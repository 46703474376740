import { useContext, ref } from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { Brand } from '~/types/brand'

const brands = ref<Brand[]>([])
const popularBrands = ref<Brand[]>([])
const loading = useLoading()
const getPopularBrandsLoading = useLoading()

export const useBrand = () => {
  const brand = ref<Brand>()
  const getBrandDetailLoading = useLoading()

  const { app } = useContext()

  const ensureBrands = async () => {
    if (brands.value?.length) return
    await getBrands()
  }

  const getBrands = () => {
    return loading.scope(async () => {
      const response = await app.$api.brand.getBrands()
      brands.value = response
    })
  }

  const getPopularBrands = (limit: number) => {
    return getPopularBrandsLoading.scope(async () => {
      const response = await app.$api.brand.getPopularBrands(limit)
      popularBrands.value = response
    })
  }

  const getByUrl = (url: string) => {
    return getBrandDetailLoading.scope(async () => {
      const response = await app.$api.brand.getBrandByUrl(url)
      brand.value = response
    })
  }

  return {
    loading: loading.value,
    getPopularBrandsLoading: getPopularBrandsLoading.value,
    getBrandDetailLoading: getBrandDetailLoading.value,

    brands,
    popularBrands,
    brand,

    ensureBrands,
    getBrands,
    getPopularBrands,
    getByUrl,
  }
}
