import { Dictionary } from 'ramda'
import {
  useContext,
  ssrRef,
  useRoute,
  readonly,
  computed,
  InjectionKey,
  provide,
  inject,
  DeepReadonly,
  Ref,
  ComputedRef,
} from '@nuxtjs/composition-api'
import {
  SiteName,
  GlobalMenus,
  RouteMetas,
  PageMetaMap,
} from './useContent.meta'
import { useLoading } from './ui/useLoading'
import {
  Menu,
  PageMeta,
  ContentLoadOptions,
  ContentPartResult,
  CmsArticle,
  ContentPart,
  SiteBanner,
  ContentLoadContext,
  Banner,
  Robots,
  Brand
} from '@/types/content'
import { ApiStatus } from '~/types/common'

interface HTMLMeta {
  title?: string
  htmlAttrs?: {
    lang: string
  }
  link?: {
    rel: string
    href: string
  }[]
  meta?: never[]
}

type ContentResult = {
  menus: DeepReadonly<Ref<Dictionary<Menu[]> | null>>
  serviceMenus: DeepReadonly<Ref<Menu[] | null>>
  siteBanners: DeepReadonly<Ref<SiteBanner[] | null>>
  mainUsps: DeepReadonly<Ref<Banner[] | null>>
  popularBrands:  DeepReadonly<Ref<Brand[] | null>>
  newBrands:  DeepReadonly<Ref<Brand[] | null>>
  brandsSlider: DeepReadonly<Ref<Brand[] | null>>

  article: DeepReadonly<Ref<CmsArticle | null>>
  content: Ref<Dictionary<ContentPartResult> | null>
  htmlMeta: ComputedRef<HTMLMeta>
  loading: Ref<boolean>

  resetContent: () => void
  resetSiteWideContent: () => void
  ensureContent: (options?: ContentLoadOptions) => Promise<void>
  setPageMeta: (meta: PageMeta) => void
}

export const contentKey: InjectionKey<ContentResult> =
  Symbol('Provider:Content')

export const provideContent = () => {
  const menus = ssrRef<Dictionary<Menu[]> | null>(null)
  const siteBanners = ssrRef<SiteBanner[] | null>(null)
  const mainUsps = ssrRef<Banner[] | null>(null)
  const serviceMenus = ssrRef<Menu[] | null>(null)
  const popularBrands = ssrRef<Brand[] | null>(null)
  const newBrands = ssrRef<Brand[] | null>(null)
  const brandsSlider= ssrRef<Brand[] | null>(null)

  const article = ssrRef<CmsArticle | null>(null)
  const pageMeta = ssrRef<PageMeta | null>(null)
  const content = ssrRef<Dictionary<ContentPartResult> | null>(null)
  const notFound = ssrRef<boolean | null>(null)
  const loading = useLoading()

  const { app, $config, ssrContext, error } = useContext()
  const route = useRoute()

  const resetContent = () => {
    article.value = null
    pageMeta.value = null
    content.value = null
    notFound.value = null
  }

  const resetSiteWideContent = () => {
    menus.value = null
    siteBanners.value = null
    mainUsps.value = null
    serviceMenus.value = null
    popularBrands.value = null
    newBrands.value = null
    brandsSlider.value = null
  }

  const ensureContent = async (options?: ContentLoadOptions) => {
    if (content.value) return

    options = Object.assign({}, options, { ensure: true })
    await loadContent(options)
  }

  const loadRouteContent = async (
    routeName: string,
    options?: ContentLoadOptions
  ): Promise<ContentLoadContext | null> => {
    const request = RouteMetas.get(routeName)?.(route.value)
    // No route meta defined, means no need to fetch specific content
    if (!request) return null

    if (request.selfLoad && !options?.selfLoad) return { request }

    if (typeof request.pageMeta === 'undefined') {
      request.pageMeta = true
    }

    // Ensure global menus load
    if (!menus.value) {
      if (!request.parts) {
        request.parts = [...GlobalMenus.keys()]
      } else {
        request.parts.push(...GlobalMenus.keys())
      }
    }

    // Ensure siteBanners load
    if (!siteBanners.value) {
      if (!request.parts) {
        request.parts = [ContentPart.SiteBanners]
      } else {
        request.parts.push(ContentPart.SiteBanners)
      }
    }

    // Ensure mainUsps load
    if (!mainUsps.value) {
      if (!request.parts) {
        request.parts = [ContentPart.MainUsps]
      } else {
        request.parts.push(ContentPart.MainUsps)
      }
    }

    // Ensure popular and new brands
    if (!popularBrands.value) {
      if (!request.parts) {
        request.parts = [ContentPart.PopularBrands]
      } else {
        request.parts.push(ContentPart.PopularBrands)
      }
    }
    if (!newBrands.value) {
      if (!request.parts) {
        request.parts = [ContentPart.NewBrands]
      } else {
        request.parts.push(ContentPart.NewBrands)
      }
    }
    if (!brandsSlider.value) {
      if (!request.parts) {
        request.parts = [ContentPart.BrandsSlider]
      } else {
        request.parts.push(ContentPart.BrandsSlider)
      }
    }

    // Make load serviceMenus once
    if (request.parts?.length && serviceMenus.value?.length) {
      const requestParts = new Set(request.parts)
      if (requestParts.has(ContentPart.ServiceMenus)) {
        requestParts.delete(ContentPart.ServiceMenus)
        request.parts = Array.from(requestParts)
      }
    }

    const result = await app.$api.content.getContent(request, options?.params)
    return {
      request,
      result,
    }
  }

  const loadContent = (options?: ContentLoadOptions) => {
    return loading.scope(async () => {
      if (options?.ensure && content.value) return

      // No route name, refuse to load content
      const routeName = route.value.name?.split('___')[0]
      if (!routeName) return

      // Try to load content for route
      let context = await loadRouteContent(routeName, options)
      if (!context) {
        // Use fallback when there are no content request for some pages
        context = await loadRouteContent('__fallback', options)
      }
      // If it's a self load content, return directly when try global loading
      if (context?.request.selfLoad && !options?.selfLoad) return

      let result = context?.result

      const firstFailed = !result || result.status !== ApiStatus.Ok
      notFound.value = result?.status === ApiStatus.NotFound

      const isWildcardPage = routeName === 'all'
      if (process.server && ssrContext && notFound.value && isWildcardPage) {
        ssrContext.res.statusCode = ApiStatus.NotFound
        error({
          statusCode: ApiStatus.NotFound,
        })
      }
      // Only use first request Article
      article.value = result?.article ?? {}

      // First failure fallback to ensure global menus loaded
      if (firstFailed && !menus.value) {
        context = await loadRouteContent('__fallback', options)
        result = context?.result
      }

      if (!result || result.status !== ApiStatus.Ok) return

      if (result.parts) {
        const parts = result.parts as Dictionary<ContentPartResult>

        // Extract global menus result
        if (!menus.value) {
          const tempMenus: Dictionary<Menu[]> = {}
          GlobalMenus.forEach((part) => {
            const menuResult = parts[part] as Menu[]
            if (menuResult) {
              tempMenus[part] = menuResult
              delete parts[part]
            }
          })

          menus.value = tempMenus
        }

        // Extract global siteBanners result
        if (!siteBanners.value) {
          siteBanners.value = parts[ContentPart.SiteBanners] as SiteBanner[]
          delete parts[ContentPart.SiteBanners]
        }

        // Extract serviceMenus result
        if (!serviceMenus.value) {
          serviceMenus.value = parts[ContentPart.ServiceMenus] as Menu[]
          delete parts[ContentPart.ServiceMenus]
        }

        // Extract mainUsps result
        if (!mainUsps.value) {
          mainUsps.value = parts[ContentPart.MainUsps] as Banner[]
          delete parts[ContentPart.MainUsps]
        }

        // Extract popular and new brands result
        if (!popularBrands.value) {
          popularBrands.value = parts[ContentPart.PopularBrands] as Brand[]
          delete parts[ContentPart.PopularBrands]
        }
        if (!newBrands.value) {
          newBrands.value = parts[ContentPart.NewBrands] as Brand[]
          delete parts[ContentPart.NewBrands]
        }
        if (!brandsSlider.value) {
          brandsSlider.value = parts[ContentPart.BrandsSlider] as Brand[]
          delete parts[ContentPart.BrandsSlider]
        }
      }

      // Extract rest content parts
      content.value = result.parts ?? {}

      // Extract page meta
      if (result.pageMeta) {
        pageMeta.value = result.pageMeta
      }

      const robotsValue = [
        article.value?.robotsNoIndex ? Robots.NoIndex : '',
        article.value?.robotsNoFollow ? Robots.NoFollow : '',
      ]
        .filter((item) => !!item)
        .join(',')

      if (robotsValue) {
        setPageMeta({
          robots: robotsValue,
        })
      }
    })
  }

  const setPageMeta = (meta: PageMeta) => {
    pageMeta.value = meta
  }

  const toHtmlMeta = (meta: PageMeta | null) => {
    if (!meta) return {}

    meta.name = SiteName
    meta.type ||= 'article'
    meta.link = `${$config.DOMAIN}${route.value.fullPath.split(/[?#]/)[0]}`
    meta.domain = $config.DOMAIN

    return {
      title: `${meta.title} - ${SiteName}`,
      htmlAttrs: {
        lang: app.i18n.locale,
      },
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: meta.link,
        },
      ],
      meta: [].concat(
        ...Object.keys(meta).map<any>((key) => {
          const value = meta[key]
          if (!value) return []

          const metas = PageMetaMap.get(key)?.map((metaKey) => {
            return Object.assign(
              {
                hid: metaKey,
              },
              metaKey.includes('og')
                ? { property: metaKey }
                : { name: metaKey },
              metaKey.includes('twitter:card')
                ? { content: 'summary_large_image' }
                : { content: value }
            )
          })
          return metas
        })
      ),
    }
  }

  const htmlMeta = computed(() => {
    return toHtmlMeta(pageMeta.value)
  })

  provide(contentKey, {
    menus: readonly(menus),
    serviceMenus: readonly(serviceMenus),
    siteBanners: readonly(siteBanners),
    mainUsps: readonly(mainUsps),
    popularBrands: readonly(popularBrands),
    newBrands: readonly(newBrands),
    brandsSlider: readonly(brandsSlider),

    article: readonly(article),
    content,
    htmlMeta,
    loading: loading.value,

    resetContent,
    resetSiteWideContent,
    ensureContent,
    setPageMeta,
  })
}

export const useContent = () => {
  const result = inject(contentKey)

  if (result == null) {
    throw new Error('content provider not set')
  }

  return result
}
